<template>
  <div class="c_course_file">
    <div class="stu-module-header">
      <div class="stu-module-title">课程文件</div>
    </div>
    <el-table
      v-loading="loading"
      :data="datalist"
      :height="500"
      ref="tableRef"
      :row-class-name="tableRowClassName"
      @row-click="onRowClick"
      stripe
    >
      <el-table-column label="序号" :width="60" align="center">
        <template slot-scope="scope">
          <span>{{ scope.$index + (current - 1) * pageSize + 1 }} </span>
        </template>
      </el-table-column>
      <el-table-column
        prop="topicTitle"
        label="标题"
        align="center"
        min-width="160px"
      >
        <template slot-scope="scope">
          <p v-html="scope.row.topicTitle"></p>
        </template>
      </el-table-column>
      <el-table-column
        prop="createTime"
        label="发布时间"
        align="center"
        min-width="120px"
      ></el-table-column>
      <el-table-column
        prop="teacherName"
        label="发布人"
        align="center"
        min-width="100px"
      ></el-table-column>
      <el-table-column prop="status" label="状态" align="center" width="90px">
        <template slot-scope="scope">
          <span :style="{ color: '#FF9B22' }">{{ scope.row.status }}</span>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="pagination"
      background
      @current-change="handleCurrentChange"
      :current-page="current"
      :page-size="pageSize"
      layout="total, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
    <el-drawer title="" :visible.sync="selectRow.drawer" size="40%" :before-close="drawerBeforeClose">
      <!-- <div class="drawer-content" v-html="selectRow.topicInfo"></div> -->
      <div style="padding: 0 20px">
        <span style="font-weight: 500">标题</span>
        <QuillEditor
          style="margin-top: 5px"
          :paramsText="selectRow.topicTitle"
        />
        <span style="font-weight: 500">内容</span>
        <QuillEditor
          style="margin-top: 5px"
          :paramsText="selectRow.topicInfo"
          @uploadFile1="uploadFile1"
          :resultMethods="'uploadFile1'"
        />
      </div>
    </el-drawer>
  </div>
</template>

<script>
import QuillEditor from "@/components/teacher/QuillEditor.vue";
import {
  QueryEduCourseTopicWorkByStu,
  UpdateEduCourseTopicByStu,
} from "@/libs/api/topic";
export default {
  name: "c_course_file",
  components: { QuillEditor },
  data() {
    return {
      loading: false,
      tableData: [],
      pageSize: 10,
      current: 1,
      total: 0,

      selectRow: {
        drawer: false,
        topicTitle: null,
        topicInfo: null,
      },
    };
  },
  computed: {
    datalist() {
      return this.tableData.slice(
        (this.current - 1) * this.pageSize,
        this.current * this.pageSize
      );
    },
    course() {
      return this.$store.getters.getStuMyCourse;
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    drawerBeforeClose(done){
      done();
      this.init();
    },
    uploadFile1(str) {
      if (null == this.selectRow.topicInfo) {
        this.selectRow.topicInfo = str;
      } else {
        this.selectRow.topicInfo = this.selectRow.topicInfo + str;
      }
    },
    init() {
      this.loading = true;
      QueryEduCourseTopicWorkByStu({ courseId: this.course.id })
        .then((res) => {
          this.tableData = res.data.list;
          this.total = res.data.total;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    handleCurrentChange(page) {
      if (page) {
        this.current = page;
        this.init();
      }
    },
    tableRowClassName({ row, rowIndex }) {
      // 把每一行的索引放进row
      row.index = rowIndex;
    },
    onRowClick(row) {
      let index = row.index;
      const i = index + (this.current - 1) * this.pageSize; // 获取到索引
      // 消除index
      delete row.index;
      if (row) {
        this.selectRow.drawer = true;
        UpdateEduCourseTopicByStu({ id: row.id }).then((res) => {
          this.selectRow = {
            drawer: true,
            ...res.data,
          };
        });
      }
    },
  },
};
</script>

<style scoped lang="less">
.c_course_file {
  padding: 20px;
  background: #fff;
  box-shadow: @shadow;

  .stu-module-header {
    margin-bottom: 20px;
  }
  .drawer-content {
    padding: 0 20px;
  }
}
</style>
